export const formatOptions = [
  { value: "jpg", label: "jpg" },
  { value: "jpeg", label: "jpeg" },
  { value: "doc", label: "doc" },
  { value: "xlsx", label: "xlsx" },
  { value: "png", label: "png" },
  { value: "zip", label: "zip" },
  { value: "txt", label: "txt" },
  { value: "pdf", label: "pdf" },
  { value: "csv", label: "csv" },
];

export const shimmer = [1, 2, 3, 4, 5, 6];
export const rowPerPages = [10, 50, 100];
export const maxFileSize = 2147483648;
export const tosterTime = 3500;
export const pageSize = 200000;
export const allowDescLength = 160;

export const FILE_TYPE = {
  folder: "folder",
  file: "file",
};

export const fileShowString = {
  more: "View more",
  less: "View less",
};

export const FOLDER_LENGTH = 6;


export const passwordRegex =
/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;

export const linkedInRegex = /(ftp|http|https):\/\/?(?:www\.)?linkedin.com(\w+:{0,1}\w*@)?(\S+)(:([0-9])+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;
export const stripe_key = 'pk_test_51MJslPSAL6qgCp4P3D3rWN7AWjo2xMbGpCs1Lqk4bpkrPWzgO7QKLZFJHJbkbss5OM5TjebYLA8Kz96BtLfL4wmZ00LIn7Gm95'

export const EDIT_PRODUCT = 'editProduct'
export const CREATE_PRODUCT = 'createProduct'

export const pdfFormat = "application/pdf";
export const xlsFormat =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
export const xlssFormat = "application/vnd.ms-excel";
export const csvFormat = "text/csv";
export const docFormat = "application/msword";
export const tiffFormat = "image/tiff";
export const tiffFormat1 = "image/tif";
export const pptxFormat = "application/vnd.openxmlformats-officedocument.presentationml.presentation";
export const docDiff =
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document";

export const permissionTypes = {
  CAN_ADD_USER: "canAddUser",
  ADD_PRODUCT: "canAddProduct",
  LIKE_PRODUCT: "canLikeProduct",
  ADD_COMMENT: "canAddComment",
  SHARE_PRODUCT: "canShareProduct",
  DUPLICATE_PRODUCT: "canDuplicateProduct",
  BLOCK_USER: "canBlockUser",
  EDIT_PRODUCT: "canEditProduct",
  EDIT_TEAM: "canEditTeam",
  DELETE_TEAM: "canDeleteTeam",
  ADD_MEMBER: "canAddMember",
  DELETE_PRODUCT: "canDeleteProduct",
  DELETE_USER: "canDeleteUser",
  VIEW_COMMENTS: "canViewComments",
  VIEW_PRODUCT: "canViewProducts",
  VIEW_TEAM: "canViewTeams",
};

export const allowedFormat = [
  "jpg",
  "jpe",
  "jpeg",
  "doc",
  "xlsx",
  "png",
  "zip",
  "txt",
  "pdf",
  "csv",
  "svg",
  "gif",
  "tif",
  "tiff",
  "eps",
  "raw",
  "cr2",
  "nef",
  "orf",
  "sr2",
  "jif",
  "jfi",
  "webp",
  "bmp",
  "psd",
  "arw",
  "svg",
  "svgz",
  "pptx"
];

export const weekDays = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];

export const dateFormats = ["dd-mm-yyyy", "mm-dd-yyyy", "yyyy-mm-dd"];
export const numberAfterDecimals = [1, 2, 3, 4];
export const currencyPositions = ["Left", "Right", "Top", "Bottom"];
export const decimalSeperators = [
  { value: ",", label: "Comma (,)" },
  { value: ".", label: "Dot (.)" },
];
export const timeFormats = ["12 Hour", "24 Hour"];

export const Reminder = [
  { value: "Self Reminder", label: "Self Reminder" },
  // { value: 'Team Reminder', label: 'Team Reminder' }
];

export const teamReminder = [
  { value: "Team Reminder", label: "Team Reminder" },
];

export const oldStateNotification = [
  {
    maintitle: "Project created",
    subTitle: "Project members",
    title: "Project",

    itemActions: {
      emailEnable: false,
      webEnable: false,
    },
    selectedTeamMembers: [],
  },
  {
    maintitle: "Project deleted",
    subTitle: "Project members",
    title: "Project",
    itemActions: {
      emailEnable: false,
      webEnable: false,
    },
  },
  {
    maintitle: "Project comments",
    subTitle: "Task assignee",
    title: "Project",
    itemActions: {
      emailEnable: false,
      webEnable: false,
    },
  },
  {
    maintitle: "Notifications",
    subTitle: "Task assignee",
    title: "Project",
    itemActions: {
      emailEnable: false,
      webEnable: false,
    },
  },
  {
    maintitle: "New announcement",
    subTitle: "Task assignee ",
    title: "Announcement",
    itemActions: {
      emailEnable: false,
      webEnable: false,
    },
  },
  {
    maintitle: "New event added in calender",
    subTitle: "Task assignee ",
    title: "Project",
    itemActions: {
      emailEnable: false,
      webEnable: false,
    },
  },
  {
    maintitle: "New message sent",
    subTitle: "Task assignee ",
    title: "Message",
    itemActions: {
      emailEnable: false,
      webEnable: false,
    },
  },
];

export const plans = [
    {
      type: 'Individual',
      role:[
        'editTeam',
        'deleteTeam',
        'deleteTeamMember',
        'blockTeamUser',
        'productFilter',
        'productBookmark',
        'productShare',
        'productEdit',
        'productDelete',
        'productShareLink',
        'emailNotificationBeforeDelete',

      ],
      routes: [
        '/setting/personal',
        '/setting/email',
        '/setting/company',
        '/setting/notification',
        '/setting/role',
        '/setting/team',
        '/Notification',
        '/teams',
        '/createteam',
        '/jointeam',
        '/teamslist',
        '/createuser',
        '/teamusers/:id',
        '/wall',
        '/dashboard',
        '/productDetail/:productId',
        '/productDetail/:productId/:teamId',  
        '/wall/createproduct',
        '/wall/progress',
        '/wall/reminders'
      ],
    },
    {
      type: 'Basic',
      role:[
        'editTeam',
        'deleteTeam',
        'deleteTeamMember',
        'blockTeamUser',
        'productFilter',
        'productBookmark',
        'productShare',
        'productEdit',
        'productDelete',
        'productShareLink',
        'emailNotificationBeforeDelete',
        
      ],
      routes: [
        '/setting/personal',
        '/setting/email',
        '/setting/company',
        '/setting/notification',
        '/setting/role',
        '/setting/team',
        '/teams',
        '/wall',
        '/dashboard',
      ] 
    } 
]

export const DELETE_TEAM = 'deleteTeam';
export const EDIT_TEAM = 'editTeam';
export const DELETE_TEAM_MEMBER = 'deleteTeamMember';
export const BLOCK_TEAM_USER = 'blockTeamUser';
export const PRODUCT_DELETE = 'productDelete';
export const PRODUCT_EDIT = 'productEdit';
export const PRODUCT_DUPLICATE = 'productDuplicate';
export const PRODUCT_SHARE_LINK = 'productShareLink';
export const PRODUCT_SHARE = 'productShare';
export const VIEW_TEAM = 'viewTeam';

export const INDIVIDUAL = 'Individual';
export const BASIC = 'Basic';
export const STANDARD = 'Standard';
export const PRO = 'Pro';
export const ENTERPRISE = 'EnterPrice';

export const tryForFreeList=[
  'Individual',
  'Basic',
  'Standard',
  'Pro',
  'Enterprise'
]

// export const EDIT_TEAM = 'edit/Team';
// export const EDIT_TEAM = 'editTeam';
