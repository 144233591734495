import { useRef, useEffect } from 'react';

function useFavIcon(favIcon,setFavIcon=false){

  const favicon = document.getElementById("favicon"); 
    useEffect(() => {
      favicon.href = favIcon;
      }, [favicon]);
    
      useEffect(() => () => {
        if (!setFavIcon) {
          favicon.href = favicon.href;
        }
      }, [])
}

export default useFavIcon;